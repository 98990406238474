<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <div class="mr-2">
            <Button 
              v-if="role?.import"
              class="main-style-button"
              label="Import"
              @click="importVisible = true"
            />
          </div>
          <div class="ml-2">
            <Button 
              v-if="role?.export"
              class="main-style-button"
              label="Export"
              @click="exportData"
            />
          </div>
        </div>
        <!--{{listData}}-->
        <BaseDatatable
          :key="componentKey"
          :tableData="listData"
          :model="dataModel"
          :options="options"
          :filterDefault="filters"
          :getFilterOptions="getFilterOptions"
          :actions="getRowActions()"
          v-on:action-click="onActionClicked"
          @filterExport="filterExportFunc"
          @onDataChange = 'DataOnChange'
        ></BaseDatatable> 
        <Dialog
          header="Delete"
          :visible.sync="showDeleteDialog"
          :contentStyle="{overflow: 'visible'}"
          :modal="true"
        >
          Are you sure you want to delete?
          <template
            #footer
          >
            <Button label="Delete" @click="deleteData()" icon="pi pi-check" class="p-button-danger" />
            <Button
              label="Cancel"
              @click="showDeleteDialog = false"
              icon="pi pi-times"
              class="p-button-warning callback-btn"
            />
          </template>
        </Dialog>
        <Dialog
          header="Import không thành công"
          :visible.sync="importVisibleError"
          :contentStyle="{overflow: 'visible', width: '650px'}"
          :modal="true"
        >
          <div class="mb-4">
            <p>Tập dữ liệu chứa {{ countError }} dòng không hợp lệ, tải file chứa lỗi và điều chỉnh cho lần nhập dữ liệu tiếp theo.</p>
            <div class="mt-4">
              <a class="txt-link-primary" :href="fileErr" download target="_blank">Bấm vào đây để tải file lỗi</a>
            </div>
          </div>
          <template
            #footer
          >
            <Button
              label="Đã hiểu"
              @click="importVisibleError = false"
              icon="pi pi-check"
              class="main-style-button"
            />
          </template>
        </Dialog>
        <Dialog
          v-if="role?.import"
          header="Import danh sách sản phẩm"
          :visible.sync="importVisible"
          :contentStyle="{overflow: 'visible', width: '650px'}"
          :modal="true"
        >
          <div class="mb-4">
            Nhấn nút chọn file để chọn file dữ liệu cần import. Nếu chưa có mẫu vui lòng chọn tải file mẫu bên dưới và điền thông tin theo form yêu cầu.
          </div>
          <div class="mt-4">
            <a class="txt-link-primary" href="../../../../template/import_product_example.xlsx" download target="_blank">Bấm vào đây để tải FILE MẪU</a>
          </div>
          <!-- <input class="file-hidden" id="file-import" type="file" name="file" accept=".xlsx" @change="changeFileImport"/> -->
          <template
            #footer
          >
            <Button
              label="Hủy bỏ"
              @click="importVisible = false"
              icon="pi pi-times"
              class="p-button-cancel"
            />
            <!-- <Button label="Chọn file" @click="importFile" icon="pi pi-check" class="main-style-button" /> -->
            <!-- @progress="progressImport" -->
            <!-- :url="`${$ConfigEvn.base_url_api}/_api/product/import`" -->
            <FileUpload 
              mode="basic"
              @before-upload="beforeUpload"
              name="import" icon="pi pi-check"
              :customUpload="true" @uploader="myUploader"
              class="main-style-button"
              :disabled="loadImport"
              :fileLimit="1"
              ref="fileUpload"
              accept=".xlsx" :maxFileSize="1000000" @error="importError" 
              @upload="importFile" :auto="true" chooseLabel="Chọn file" />
          </template>
          <div class="loading-import" v-if="loadImport">
            <span>Đang tải...</span>
          </div>
        </Dialog>


        <!-- <Dialog
          header="Cảnh báo"
          :visible.sync="importVisibleError"
          :contentStyle="{overflow: 'visible', width: '650px'}"
          :modal="true"
        >
          <div class="mb-4">
            <p>Import lỗi ({{importErrorData.length}}):</p>
            <div v-for="(err, index) in importErrorData" :key="index">
              <p>- {{ err }}</p>
            </div>
          </div>
          <template
            #footer
          >
            <Button
              label="Đồng ý"
              @click="importVisibleError = false"
              icon="pi pi-check"
              class="main-style-button"
            />
          </template>
        </Dialog> -->
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
import BaseDatatable from './list'
// import gql from "graphql-tag";
// import Popup from './popup'
import AuthenticationApp from '@/core/AuthenticationApp';
import ApiRepository from "@/core/ApiRepository";
export default {
  components: {
    BaseDatatable,
    // Popup
  },
  data() {
    return {
      filterExport: {},
      countError: 0,
      fileErr: null,
      loadImport: false,
      importVisibleError: false,
      importErrorData: [],
      importVisible: false,
      filters : {},
      filterOptions: {},
      options: [],
      listData: [],
      modelName: '',
      dataModel: null,
      title: '',
      componentKey: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      deleteId: null,
      deleteName: null,
      showDeleteDialog: false,
      whereKeyList:{
        where_key: {
          /*accounts: {
            role: {_eq: "admin"},
            deleted: {_eq: false}
          },*/
          deleted: {_eq: false}
        }
      },
      page_transaction:null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        export: false,
        import: false,
      },
      view_club: false
    }
  },
  mounted () {
    this.initParams();
  }
  ,
  async created() {
    this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    //console.log('this.dataModel:',this.dataModel);
    this.title = this.dataModel.name
    this.$store.commit('setLoading', true);
    if(this.$route.params.reload) {
      window.location.reload();
    }
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_PRODUCT);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }
    // console.log("arr_role:" ,arr_role)
    this.role.add = (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) ? false:true;
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false:true;
    this.role.import = (arr_role.indexOf(this.$constants.ROLE.IMPORT) < 0) ? false:true;
    this.role.export = (arr_role.indexOf(this.$constants.ROLE.EXPORT) < 0) ? false:true;
    // var arr_role_clubs = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_CLUBS);
    // // console.log('role_clubrole_club',arr_role_clubs)
    // if (arr_role_clubs.indexOf(this.$constants.ROLE.VIEW) < 0){
      //   this.view_club = false;
      // }else{
        //   this.view_club = true;
        // }
        // console.log('this.view',this.view_club)
      },
      watch: {
        '$route': 'initParams'
      },
      methods: {
        initParams() {
          this.$store.commit('setLoading', true);
          this.modelName = this.$route.meta.model;
          this.dataModel = DataServices.getModel(this.modelName);
          this.title = this.dataModel.label;
          this.callbackOptions();
          console.log("Here is the company list", this.$store.getters.company);
          console.log("Here is the company list 2", AuthenticationApp.getListCompany());
        },
    async callbackOptions() {
      var fields = this.dataModel.getDisplayFields();
      for (let index = 0; index < fields.length; index++) {
        var attributes = [];
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          // console.log("SDFGHJK",element)
          var optionsData = [];
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          var where_ = {}
          
          var result;
          if( element['name']== "service_name"){
            // console.log("element",element)
            let {data} = await this.$apollo.query({
              query: DataServices.getList("service", {"fields" : "id name"}),
              variables: {
                where_key : {
                  deleted : {_eq: false},
                  parent_id : {_is_null: true}
                },
                orderBy:[{ordinal: 'desc'},{created_at : 'desc'}]
              }
            })
            if (data) {
              result = data[Object.keys(data)];
              if (result) {
                for (let index = 0; index < result.length; index++) {
                  const ele = result[index];
                  optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
                }
              }
            }
          }else{
            console.log("element['name']:",element.where_option)
            if (element.where_option){
              where_ = element.where_option
            }
            let {data} = await this.$apollo.query({
              query: DataServices.getList(element.models.tableName, {"fields" : attributes.join(',')}),
              variables: {where_key : where_}
            })
            if (data) {
              result = data[Object.keys(data)];
              if (result) {
                for (let index = 0; index < result.length; index++) {
                  const ele = result[index];
                  optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
                }
              }
            }
          }
          console.log("optionsData",optionsData)
          this.options[element.key] = optionsData;
        }
        this.componentKey++;
      }
    },
    DataOnChange(data,f){
      if(f.type == 'Checkbox'){
        let dataUpdate = {}
        if(f.key == 'active'){
          dataUpdate['active'] = data[f.key]
        }
        let variables = {
          'data_update': dataUpdate,
          'where_key': {
            'id': {'_eq': data.id}
          }
        };
        this.$apollo.mutate({
          mutation: DataServices.updateData(this.modelName),
          variables: variables
        }).then(() => {})
      }
    },
    onChange() {
      this.$store.commit('setLoading', true);
      this.$apollo.queries.listData.refresh()
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name)
      var result = []
      if (field.type == 'Selection') {
        if (field.options ) result = field.options;
        else if(field.models && this.options[name]) result = this.options[name];
      } else {
        result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
      }
      return result;
    },
    getRowActions() {
      var actions = {}
      var that = this;
      if (this.role.add) {
        actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
      }
      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        if (that.role.edit) {
          buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`};
        }
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
        // if (that.role.delete) {
        //   buttons.delete = {name: 'delete', icon: 'trash', key: 'id'}
        // }
        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }

      return actions
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'reexamine':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key],query: { reexamine: true }  })
          } else  {
            this.addId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    async myUploader(event) {
      this.loadImport = true;
      //event.files == files to upload
      console.log("event", event);
      var file = event?.files[0];
      let formData = new FormData();
      formData.append('file', file)
      var headerUpload = {
          headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
          }
        };
      var that = this;
      await ApiRepository.post('/_api/product/import', formData,headerUpload)
      .then(async (res) => {
          var is_process = 0;
        var callData  =  window.setInterval(async () => {
          that.$commonFuction.is_loading(false);
          if (is_process == 1){
            return false
          }
          is_process = 1;
          var item_import  = (res["data"]["data"]) ? res["data"]["data"]:null
          var dataRequestCheck = {
            url_file:item_import.url_file,
          }
          console.log("dataRequestCheckdataRequestCheck:",dataRequestCheck)
          try {
            const headers = {
              "Authorization": "Bearer "+(await that.$AuthenticationApp.getToken()),
            };
            var item_res = await ApiRepository.post('_api/product/check-status-import', dataRequestCheck, {headers});
            var item_check  = (item_res["data"]["data"]) ? item_res["data"]["data"]:null
            console.log("item_checkitem_check:",item_check)
            if (item_check){
              var key_process = item_check.status
              if (key_process == 'SUCCESS'){
                clearInterval(callData);
                that.$toast.add({
                  severity: "success",
                  summary: "Thông báo",
                  detail: "Import thành công",
                  life: 3000,
                });
                that.loadImport = false;
                that.importVisible = false;
                that.componentKey++;
                that.$refs.fileUpload.clear();
                that.$refs.fileUpload.uploadedFileCount = 0;
              }else if (key_process == 'ERROR'){
                clearInterval(callData);
                that.loadImport = false;
                that.importVisibleError = true;
                that.fileErr = item_check?.file_err;
                that.countError = item_check?.errorArr ? item_check.errorArr.length : 0;
                that.$refs.fileUpload.clear();
                that.$refs.fileUpload.uploadedFileCount = 0;
              }
            }
            is_process = 0;
            //
            return true
          } catch (err) {
            that.$toast.add({
              severity: "error",
              summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000,
            });
            that.loadImport = false;
            that.importVisible = false;
            that.$refs.fileUpload.clear();
            console.log(err);
            return false;
          }

          /* step++;
          if (step > vm.seconds) {
            step = 0;
            var stop = await vm.checkImportProcessing();
            if (stop) {
              vm.$store.commit('setLoading', false);
              clearInterval(callData);
            }
          }*/
        }, 5000);
      }).catch((err) => {
        console.log("err", err);
        // if (err?.response?.data?.message) {
        //   this.importVisibleError = true;
        //   this.fileErr = err?.response?.data?.message.file_err;
        //   this.countError = err?.response?.data?.message.errorArr ? err.response.data.message.errorArr.length : 0;
        // } else {
          this.$toast.add({
            severity: "error",
            summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
            detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
            life: 3000,
          });
        // }
        this.loadImport = false;
        this.componentKey++;
        this.$refs.fileUpload.clear();
        this.$refs.fileUpload.uploadedFileCount = 0;
      })
    },
    filterExportFunc(data) {
      this.filterExport = data;
    },
    async exportData() {
      this.$commonFuction.is_loading(true);
      var headers = {
        "Authorization": "Bearer "+( await this.$AuthenticationApp.getToken()),
      }
      
      var filters = {};
      if (this.filterExport?.filters) {
        for (const [key, value] of Object.entries(this.filterExport?.filters)) {
          filters[key] = value.value;
        }
      } else {
        for (const [key, value] of Object.entries(this.filterExport)) {
          if (key != 'filters') {
            filters[key] = value.value;
          }
        }
      }
      
      ApiRepository.post("_api/product/export", {filters: filters}, {headers}).then((res) => {
        this.$commonFuction.is_loading(false);
        if (res?.data?.data?.linkExcel) {
          window.open(res?.data?.data?.linkExcel);
        }
      }).catch(async error => {
          console.log('error', error);
          this.$toast.add({
              severity: 'error',
              summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000
          });
          this.$commonFuction.is_loading(false);
      });
    },
    beforeUpload(request) {
        this.loadImport = true;
        // console.log(request);
        // request.xhr.open('POST', this.import);
        // request.xhr.setRequestHeader('Content-Type', 'application/json');
        // request.xhr.setRequestHeader('Authorization', `Bearer ${this.$store.getters.token ? this.$store.getters.token : null}`);
        return request;
    },
    async importError(error) {
      const err = JSON.parse(error.xhr.response);
      if (err?.message) {
        // if (err.message.length > 0) {
        //   this.importVisibleError = true;
        //   this.importErrorData = err.message;
        // } else {
        //   this.$toast.add({
        //     severity: "error",
        //     summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
        //     detail: err.message,
        //     life: 3000,
        //   });
        // }
        console.log(err?.message);
        this.importVisibleError = true;
        this.fileErr = err?.message?.file_err;
        this.countError = err?.message?.errorArr ? err.message.errorArr.length : 0;
      } else {
        this.$toast.add({
          severity: "error",
          summary: this.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
          detail: this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
          life: 3000,
        });
      }
      this.loadImport = false;
    },
    async importFile(data) {
      console.log("data", JSON.parse(data.xhr.response));
      this.$toast.add({
        severity: "success",
        summary: "Thông báo",
        detail: "Import thành công",
        life: 3000,
      });
      this.loadImport = false;
      this.importVisible = false;
      this.componentKey++;
    },
    // async deleteData() {
    //   this.showDeleteDialog = false;
    //   console.log("testttttttttt");
    //   this.$commonFuction.is_loading(true);
    //   var resDataAccount = await this.$CoreService.getAccountByUid(this.deleteId);
    //   if (resDataAccount && resDataAccount.length > 0){
    //     //res[0].id;
    //     // var account_id = resDataAccount[0].id;
    //     // var phone = this.$commonFuction.convertPhone(resDataAccount[0].account);
    //     var uid = this.deleteId;
    //     var that = this;
    //     //Xóa account cũ
    //     // id mới dc thêm vào
    //     var userUpdate = {
    //       'deleted': true,
    //     };
    //     var accountUpdate = {
    //       'deleted': true,
    //     }
    //     var queryUpdate = `
    //               mutation update_users_multil($users_object: users_set_input,$accounts_object: accounts_set_input,$group_user_object: group_user_set_input) {
    //                 update_users(where: { id: {_eq: "${uid}"}}, _set: $users_object) {
    //                   affected_rows
    //                   returning {
    //                     id
    //                   }
    //                 }
    //                 update_accounts(where: { uid: {_eq: "${uid}"}}, _set: $accounts_object) {
    //                   affected_rows
    //                   returning {
    //                     id
    //                   }
    //                 }
    //                 delete_jwt_token(where: { uid: {_eq: "${uid}"}}) {
    //                   returning {
    //                     id
    //                   }
    //                 }
    //               }
    //         `;

    //     let variables = {
    //       users_object: userUpdate,
    //       accounts_object: accountUpdate,
    //     };
    //     that.$apollo.mutate({
    //       mutation: gql(queryUpdate),
    //       variables: variables
    //     }).then(async (res) => {
    //       console.log(res);
    //     }).then(() => {
    //       var router_back = {path: '/admin/list-users'};
    //       if (!that.$commonFuction.isEmpty(router_back)){
    //         console.log("that.page_transaction:",that.page_transaction);
    //         router_back.query = { page_transaction: that.page_transaction.toString() };
    //       }
    //       that.$router.push(router_back);
    //       that.$commonFuction.is_loading(false);
    //       that.$toast.add({ severity: 'success', summary: 'Success', detail: 'Delete data successfully', life: 3000 })
    //       //that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
    //       // window.location.reload()
    //       that.componentKey++;
    //     });
    //   }
    // }
  },

}
</script>
<style lang="css">
.p-calendar .p-datepicker { width: 280px; padding: 0px; padding-top: 5px;}
.p-calendar .p-datepicker td, .p-calendar .p-datepicker td a {padding: 0px !important;}
.p-calendar .p-datepicker td span {padding: 0px !important; width: 30px !important; height: 30px !important;}
.p-datepicker .p-datepicker-header{padding: 5px !important;}
.p-button-warning-o .p-button-label-o{
  color: #142053;
}
.p-button-warning .pi-times{
  color: #142053;
}
</style>